<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div class="flex Fbg">
      <van-radio-group v-model="query.train_type">
        <van-cell-group inset class="flex margin-0">
          <van-cell
            title="月度培训"
            clickable
            @click="query.train_type = '1'"
            class="w-100"
            :class="{
              'color-808080': query.train_type == '2',
              'color-DFAE00': query.train_type == '1'
            }"
          ></van-cell>
          <van-cell
            title="周培训"
            clickable
            @click="query.train_type = '2'"
            class="w-100"
            :class="{
              'color-808080': query.train_type == '1',
              'color-DFAE00': query.train_type == '2'
            }"
          ></van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-search
        v-model="query.name"
        show-action
        background="#FFF"
        @search="onSearch"
        placeholder="资料名称"
      >
        <template #action>
          <van-icon
            name="search"
            class="size-25"
            color="#0F60A7"
            @click="onSearch"
          />
        </template>
      </van-search>
    </div>
    <div v-if="query.train_type == '1'">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="Fbg"
      >
        <van-cell-group inset class="flex">
          <van-cell
            title="序号"
            class="w-60 flex alignCenter justifyCenter padding-5"
          />
          <van-cell title="资料名称" class="flex alignCenter padding-5" />
          <van-cell
            title="上传日期"
            class="w-150 flex alignCenter justifyCenter padding-5"
          />
        </van-cell-group>
        <van-cell-group
          inset
          v-for="(item, index) in list"
          :key="item.label"
          class="flex"
          @click="goToPreviewPage(item.id)"
        >
          <van-cell
            :title="index + 1"
            class="w-60 flex alignCenter justifyCenter padding-5"
          />
          <van-cell
            :title="item.train_type"
            class="flex alignCenter padding-5"
          />
          <van-cell
            :title="item.train_start_date"
            class="w-150 flex alignCenter justifyCenter padding-5"
          />
        </van-cell-group>
      </van-list>
    </div>
    <div v-if="query.train_type == '2'">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="Fbg"
      >
        <van-cell-group inset class="flex">
          <van-cell
            title="序号"
            class="w-60 flex alignCenter justifyCenter padding-5"
          />
          <van-cell title="资料名称" class="flex alignCenter padding-5" />
          <van-cell
            title="上传日期"
            class="w-150 flex alignCenter justifyCenter padding-5"
          />
        </van-cell-group>
        <van-cell-group
          inset
          v-for="(item, index) in list"
          :key="item.label"
          class="flex"
          @click="goToPreviewPage(item.id)"
        >
          <van-cell
            :title="index + 1"
            class="w-60 flex alignCenter justifyCenter padding-5"
          />
          <van-cell
            :title="item.train_type"
            class="flex alignCenter padding-5"
          />
          <van-cell
            :title="item.train_start_date"
            class="w-150 flex alignCenter justifyCenter padding-5"
          />
        </van-cell-group>
      </van-list>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, watch } from "vue";
import http from "../../../api/http";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const finished = ref(false);
    const loading = ref(false);
    const list = ref([]);
    const query = reactive({
      name: "",
      method: "query",
      queryId: 1000,
      t: "CT_TRAIN_DOCUMENT",
      train_type: 1
      // page:1
    });
    const onLoad = () => {
      // http.get(query)
      // .then(()=>{
      loading.value = false;
      finished.value = true;
      // })
    };
    const onSearch = page => {
      query.page = page;
      http.get(query).then(res => {
        list.value = res.map;
      });
    };

    const goToPreviewPage = id => {
      router.push({
        path: "/fileDetail/" + route.name + "/" + id,
        query: { train_type: query.train_type }
      });
    };
    watch(
      () => query.train_type,
      (newval, oldval) => {
        if (newval != oldval) {
          onSearch(1);
        }
      }
    );
    onMounted(() => {
      onSearch(1);
      query.train_type = route.query.train_type ? route.query.train_type : 1;
      window.addEventListener("load", () => {
        router.push(route.path);
        query.train_type = 1;
      });
    });
    return {
      finished,
      loading,
      list,
      query,
      onSearch,
      onLoad,
      goToPreviewPage
    };
  }
};
</script>
